.ContactView{
    padding-left: 2.5%;
    padding-right: 2.5%;
    max-width: 1920px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100svh;
    background-color: #00000005;
}

.ContactView .ContactTitle{
    text-align: center;
    font-size: 2.5svh;
    font-weight: 600;
    margin-top: 5%;
}

.ContactView .ContactBox{
    box-shadow: rgba(0, 0, 0, 0.2) 4px 4px 16px;
    background: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2.5%;
    
}
.ContactView .ContactBox .ContactRow{
    display: flex;
    flex-direction: column;
    
}
#QuickLinks{
    color: blue;
    font-size:1.5svh;
}
#QuickLinks:hover{
    font-size: 1.62svh;
    transition: .6s;
}
.ContactView .ContactBox input{
    width: 75vh;
    padding: 1vh;
    border-radius: 5px;
    margin-bottom: 5%;
    border-width: 1px;
}

.ContactRow h7{
    font-size: 12px;
    color: #ababab;
    margin-bottom: 1%;
}

.ContactView .ContactBox textarea{
    width: 75vh;
    padding: 1vh;
    border-radius: 5px;
    margin-bottom: 5%;
    border-width: 1px;
    height: 25vh;
}


.ContactView .ContactBox button{
    padding: 1vh;
    padding-left: 5vh; padding-right: 5vh;
    border-radius: 10px;
    margin-bottom: 5%;
    border-width: 1px;
    border-width: 0;
    background-color: #34AED4;
    color: white;
    cursor: pointer;
}


.ContactView .ContactBox button:hover{
    color: #34AED4;
    background-color: white;
    transition: .3s;
}

@media only screen and (max-width: 1200px) {
    .ContactView .ContactBox input{
        width: 45vh;
        align-self: center;
    }
    .ContactView .ContactBox textarea{
        width: 45vh;
        align-self: center;
    }
    .ContactView{
       overflow-x: hidden;
    }
    .ContactRow h7{
        font-size: 6px;
        text-align: center;
    }
}