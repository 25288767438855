.circular-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px; /* Adjust the dimensions as needed */
    width: 50px;  /* This should be the same as height for a perfect circle */
    background-color: #f0f0f0; /* You can set a background color if the image has transparency */
    border-radius: 50%; /* This creates the circular shape */
    overflow: hidden; /* Clip the image to the circular container */
}

.circular-image {
    /* max-width: 100%; */
    /* max-height: 100%; */
    min-width: 50px; 
    min-height: 50px;
    display: block;
    border-radius: 50%; /* Make sure the image itself is also circular */
}

#ImageProfile img:hover{
    opacity: .7;
    scale: 1.1;
    transition: .6s;
}
