.Home {
    
}

.HomeIntro {
    width: 100%;
    max-width: 1920px;
    height: 100%;
    display: flex;
    flex-direction: row;

}

.HomeIntro #lefthome,
#righthome {
    width: 50%;
}

#righthome img {
    width: 100%;
}

.HomeIntro #lefthome {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5%;
}

.HomeIntro #lefthome h1,
h2,
h3 {
    margin: .5%;
}

.HomeIntro h1 {
    font-weight: 400;
    font-size: 7.5svh;
}

.HomeIntro h2 {
    font-weight: 400;
    font-size: 5svh;
}

.HomeIntro h3 {
    font-weight: 400;
    font-size: 2.5svh;
}

.HomeIntro .HomeText {
    margin-right: 2.5%;
}

.HomeIntro .blue {
    color: #34AED4;
    font-weight: 700;
}


.HomeIntroDesign {
    width: 90%;
}

#DisplayHomeButtons {
    display: flex;
    gap: 5%;
    margin-top: 2.5%;
}

#DisplayHomeButtons #HomeButtonIntern {
    background-color: #34AED4;
    color: white;
    padding: 2.5%;
    width: 35%;
    text-align: center;
    border-radius: 30px;
    font-weight: 600;
}

#DisplayHomeButtons #HomeButtonFreelancer {
    color: #34AED4;
    background-color: white;
    padding: 2.5%;
    width: 35%;
    text-align: center;
    border-radius: 30px;
    font-weight: 600;
    border-width: 1px;
    border-style: solid;
}


.ServiceTitle {
    font-weight: 700;
    padding-left: 1%;
}

#DisplayCompanies {
    display: flex;
    flex-direction: column;
    padding-left: 1%;
    margin-top: 2.5%;
    margin-bottom: 10%;
}

.PopularServices {
    max-width: 1920px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2.5%;
    margin-top: 1.25%;
}

.PopularServices .Service {
    height: 16vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}

.PopularServices .Service .ServiceCategory {
    border-bottom: 0;
}

.PopularServices .Service h3 {
    font-weight: 400;
}

.PopularServices .Service img {
    width: 25svh;
}

@media only screen and (max-width: 1024px) {
    .Home {
        padding: 2.5%;
        padding-bottom: 100%;
    }

    .HomeIntro h1 {
        font-weight: 400;
        font-size: 5svh;
    }

    .HomeIntro h2 {
        font-weight: 400;
        font-size: 2.5svh;
    }

    .HomeIntro h3 {
        font-weight: 400;
        font-size: 1.25svh;
    }

    .Home .HomeText {
        margin-top: 2.5%;
    }

    .Home .HomeText h1 {
        font-size: 40px;
        text-align: center;
        padding-top: 2%;
    }

    .PopularServices .Service {
        margin-right: 1.25%;
        margin-left: 1.25%;
        height: 12.5svh;
        width: 12.5svh;
        position: relative;
    }

    .PopularServices .Service h3 {
        font-weight: 400;
        font-size: 12px;
    }

    .PopularServices .Service .ServiceCategory {
      
    }

    .ServiceTitle {
        font-weight: 700;
        text-align: center;
        font-size: 2.5svh;
    }
    .PopularServices .Service {
        height: 4vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    
    }
    .PopularServices .Service img {
        width: 10svh;
        height: 10svh;
        object-fit: contain;
    }
    
}


@media only screen and (max-width: 768px) {

    .PopularServices {
        height: 7.5vh;
    }

    .PopularServices .Service {
        height: 70%;
        margin-top: 0%;
    }

    .ServiceTitle {
        margin-top: 0;
    }
    #DisplayCompanies {
       margin-top: 10%;
    }
    
}