#ApplicantsPage {
    height: 100vh;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* justify-content: center; */
}

#ApplicantsPage #ApplicantsWrapper {
    /* border-style: solid; */
    /* border-width: .5px; */
    padding: 2.5%;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    /* justify-content: center; */
    width: 100%;
    height: 80svh;
}
#LeftApplicants{
    display: flex;
    flex-direction: column;
    width: 47.5%;
    border-right-style: solid;
    border-width: 0.25px;
    margin-right: 2.5%;
    padding-right: 2.5%;
    border-color: #00000050;
}
#ApplicantsPage #ApplicantHeader {
    /* text-align: center; */
}

#ApplicantsTopArea{
    font-size: 1.5svh;
    margin-bottom: 1%;
    text-align: left;
}

#ApplicantsDescription{
    font-size: 1.5svh;
    margin-bottom: 2.5%;
    line-height: 2svh;
    text-align: justify;
    padding-right: 2.5%;
}

#ApplicantsLayout{
    width: 50%;
}
#ApplicantSquare{
    border-style: solid;
    width: 25%;
    padding: 1%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-width: .5px;
    border-radius: 10px;
}

#ApplicantPreview{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom-style: solid;
    border-bottom-width: .5px;
    padding: 1%;
    margin-top: 1%;

}
#ApplicantsMap{
    color: black;
    font-size: 1.75svh;
}
#ApplicantsMap:hover{
    color: #00000090;
    transition: .6s;
    scale: 1.1;
}
#AcceptApplicant{
    cursor: pointer;
    border-radius: 50%;
}

#ColorCircle{
    color: #34aed4;
}
#ColorCircle:hover{
    color: #34aed460;
    transition: .6s;
}