.ApplyForasPage{
    background-color: white;
    max-width: 1920px;
    padding: 2.5%;
    display: flex;
}

.ApplyForasPage .ForsaDetails{
    display: flex; flex-direction: column;
    width: 75%;
    border-style: solid;
    border-width: 0;
    border-right-width: .1svh;

}

.ForsaDetails .leftDetails{
    background-color:white;
    margin-left: 5%;
    padding:1%;
    padding-bottom: 0;
}

.ForsaDetails h4 {
    color: black;
}

.ForsaDetails .leftDetails .leftProfile{
    display: flex; flex-direction: row;
    align-items: center;
}
.ForsaDetails .leftDetails img{
    height:7.5vh;
    width: 7.5vh;
    border-radius: 20px;
    
}
.ForsaDetails .leftDetails .ProfileDetails{
    align-self: center;
}
.ForsaDetails .leftDetails .ProfileDetails img{
    height:2.25vh;
    padding-top: 0;
}
.ForsaDetails .leftDetails .leftProfile .ProfileDetails{
    margin-left: 1.25%;
}

.ForsaDetails .leftDetails .moreDetails h4{
    font-family:"convergence";
}

.ForsaDetails .leftDetails a{
    color:black; text-decoration: none;
}

.ForsaDetails .rightDetails{
    background-color:white;
    width:70%;
    margin-left: 2.5%;
    padding:1%;
    
}

.ForsaDetails .rightDetails .jobUpTag{
    display: flex;
    flex-direction: row;
}

.ForsaDetails .rightDetails .jobUpTag .jobTitle{
    width:85%
}
.ForsaDetails .rightDetails .SecondTag{
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
   align-content: center;
}

.ForsaDetails .rightDetails .SecondTag .duration{
    margin-right: 5%;
}


.ForsaDetails .rightDetails .SecondTag .timeframe h4{
    font-family: 'convergence';
    font-weight: 400;
    font-size: 1.5vh;
}
.ForsaDetails .PropertyTags{
    margin-left: 3.25%;
}
.ForsaDetails .rightDetails .ThirdTag{
    margin-top: 2.5%;
}
.ForsaDetails .rightDetails .ThirdTag #AboutThisRole{
    font-weight: 500;
    padding: .5%;
    margin: .5%;
    padding-left: 0;
    font-size: 1.75svh;
}

.ForsaDetails .rightDetails .ThirdTag #ThirdDesc{
    font-weight: 300;
    padding: .5%;
    margin: .5%;
    padding-left: 0;
    font-size: 1.5svh;
    line-height: 1.75svh;
    text-align: justify;

}
.ForsaDetails .rightDetails .ThirdTag ul{
    margin-top: 0;
}
.ForsaDetails .rightDetails .ThirdTag ul li{
}


.ForsaDetails .rightDetails .FourthTag{
    display: flex; flex-direction: row; margin-top: 2.5%;
}

.ForsaDetails .rightDetails .FourthTag .submittedpeople{
    width:85%;
}

.ForsaDetails .rightDetails .FourthTag button{
    align-self: center;
    background-color: #34AED4; border-style: none;
    font-size: 2vh;
    color:white;
    padding-left: 4vh;
    padding-right: 4vh;
    height: 5vh;
}

.SimilarForasSection{
    width: 25%;
}
.SimilarForas{
    display: flex; flex-direction: column; 
    align-items: center;
}

.SimilarForas .tab{
    width:90%;
    background-color: white;
    border-width: .1svh;
    border-style: solid;
    border-color: #00000050;
    margin-bottom: 2.5%;
    border-radius: 10px;
    padding: 2.5%;
}
.tab h4 {
    color: black;
}
.tab .similarjobTitlte{
    width:100%;
    margin-left: 2.5%;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.similarjobTitlte #similarjobTitlteimg{
    width: 50px;
    height: 50px;
    margin-right: 2.5%;
    border-radius: 5px;
}
#SimilarForasTop h4{
    margin: 0;
    font-size: 1.5svh;
    margin-top: 2.5%;
    font-weight: 600;
}

#SimilarForasTop h5{
    margin: 0;
    font-size: 1.25svh;
    margin-top: 2.5%;
    color: #00000060;
}

.tab .similarjobDesc{
    margin-left: 2.5%;
    margin-right: 2.5%;
    height:50%;
    font-size: 1.5svh;
    color: #00000080;
}

.tab .endingSimilarJob{
    display: flex;
    flex-direction: row;
    margin-left: 2.5%;
    margin-right:2.5%;
    align-items: center;
}

.tab .endingSimilarJob h4{
    display: flex;
    flex-direction: row;
    margin-left:2.5%;
    margin-right: 2.5%;
    width:65%;
}
.tab .endingSimilarJob button{
    padding:2.5%;
    padding-left: 4%;
    padding-right: 4%;
    border-style: none;
    background-color: #34AED4;
    color:white;
    height:100%;
    font-size: 16px;
    margin-top: 1.5%;
    cursor: pointer;
}
.tab .endingSimilarJob button:hover{
    color: #34AED4;
    background-color:white !important;
    transition: 0.5s;
}

.file-input-container {
    display: inline-block;
    position: relative;
    width: 5svh; 
    height: 5svh;
    border: 2px solid #ccc;
    border-radius: 10px;
    text-align: center;
    vertical-align: middle;
    line-height: 6svh; /* Center the text/icon vertically */
    cursor: pointer;
    background-color: #f9f9f9;
  }
  
  .file-input-container:hover {
    background-color: #e9e9e9;
  }
  
  .file-input-container input[type='file'] {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
  }
  
  .file-input-icon {
    font-size: 2.5svh;
    color: #555;
  }
  
.tab .endingSimilarJob button:hover{
    background-color: #0F4789;

}

.backdrop {
    background-color: #363636;
}

#displayApplyTag{
    display: flex;
    gap: 2.5%;
    flex-direction: row;
    /* justify-content: center; */
    align-items: center;
    width: 100%;
    /* margin-left: 15%;     */
}

#ApplyForasTag {
    background-color: #d9d9d990;
    font-size: 1svh;
    padding: 2.5%;
    text-align: center;
    border-radius: 10px;
}

#ApplyForasTag h3 {
    color: black;
    font-weight: 400;
    font-style: italic;
    text-wrap: nowrap;
}

#ApplyNowButton {
    border-radius: 10px;
    height: 100%;
    border-style: none;
    padding-top: 1.25vh;
    padding-bottom: 1.25vh;
    padding-left: 3vh;
    padding-right: 3vh;
    font-weight: 400;
    font-size: 1.75svh;
    font-style: italic;
    color: white;
    background-color: #34AED4;
    cursor: pointer;
}

#ApplyNowButton:hover{
    background-color: white;
    color: #34AED4;
    font-weight: 600;
    transition: .4s;
}

#jobtitleNew{
    font-size: 2.75svh;
}

#SimilarJobApply{
    text-wrap: nowrap;
    border-radius: 10px;
    padding: 3.5%;
    padding-left: 5%;
    padding-right: 5%;
    font-size: 1.75svh;
}