#AddCreditsButton:hover{
    color: white;
}

#GetCreditsInputWrapper{
    display: flex;
    justify-content: center;
}

#GetCreditsInput{
    width: 100%;
    padding: 1vh;
    padding-left: 5vh;
    padding-right: 5vh;
    border-radius: 10px;
    margin-bottom: 5%;
    border-width: 1px;
}