.ProfileJobSection .ProfileJobTab{
    background-color: white;
    border-radius: 10px;
    border-style: solid;
    border-width: 1px;
    border-color: black;
    margin-bottom: 5%;
    padding: 2.5%;
}

.ProfileJobSection .ProfileJobTab .ProfileJobDesc{
    padding-left:2.5vh;
    padding-right:2.5vh;

}

.ProfileJobSection h4{
    color: black;
}