.NotificationsPage{
    display: flex;
    min-height: 100svh;
}

.NotificationsPage .NotificationTitle{
    text-align: center;
}


.NotificationWrapper{
    display: flex; 
    flex-direction: row;
}


.NotifyTab{
    width:90%;
    background-color: white;
    margin-left: 2.5%;
    /* border-radius: 10px; */
    padding:1%;
    /* padding-left: 1%; */
    /* padding-right: 2%; */
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
    margin-bottom: 2%;
    border-style: solid;
    border-width: 0;
    border-bottom-width: .5px;
    border-color: #00000050;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.NotifyRight{
    width:100%;
}
.NotificationPanel{
    width: 100%;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
}
.UpcomingDates{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width:45%;
    margin-left: 2.5%;
    margin-bottom: 1%;
    height: 20svh;
    margin-top: 2.5%;
    border-bottom-style: solid;
    border-bottom-width: .5px;
}

.lineartab{
    /* background-image: linear-gradient(90deg,#ced9e8,white,white); */
}
#UpcomingNotifications{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* background-color: blue; */
    width: 80%;
}

.datedesign{
    color:#34AED4;
}

#leftNotifications{
    width: 20%;
    padding: 0.5%;
    border-style: solid;
    border-width: 0;
    border-right-width: 0.5px;
    border-color: #00000050;
    margin-top: 2.5%;
    height: 100%;
    text-align: center;
}
#leftNotifications h3{
    font-size: 1.5svh;
    padding: 5%;
    color: #00000050;
    font-style: italic;
}
#leftNotifications button{
    margin-bottom: 10%;
    padding: 5%;
    font-size: 1.5svh;
    border-radius: 10px;
    border-style: none;
    font-weight: 600;
}

@media only screen and (max-width: 500px) {
    .NotificationWrapper{
        flex-direction: column;
    }
.NotifyRight{
    width:100%;
}
.NotifyRight .UpcomingDates{
}
}