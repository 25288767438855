.ProfilePage {
  padding-top: 2.5%;
  min-height: 100svh !important;
}

.ProfilePage .Experience {
  background-color: white;
  padding-left: 2.5%;
  padding-right: 2.5%;
  border-style: solid;
  border-width: 0;
}

.ProfilePage .BannerSection .BackgroundProfile {
  height: 100%;
  width: 100%;
}

.ProfilePage .ProfileTag {
  margin-left: 5%;
  display: flex;
  flex-direction: row;
}

.ProfilePage .ProfileImg {
  width: 10svh;
  height: 10svh;
  border-radius: 50%;
}

.ProfilePage .ProfileDesc {
  margin-left: 1.25%;
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5%;
}

.ProfilePage .AllProfile {
  display: flex;
  flex-direction: row;
  min-height: 75svh;
}

.ProfilePage .leftSide {
  margin-left: 5%;
  width: 25%;
  margin-bottom: 2.5%;
}

.ProfilePage .ProfileTag .applybutton {
  width: 33%;
  align-self: center;
}

.ProfilePage button {
  width: 50%;
  height: 4vh;
  border-radius: 1vh;
  border-style: none;
  color: white;
  background-color: #34aed4;
  font-size: 1.75vh;
  cursor: pointer;
}

.ProfilePage .rightside {
  margin-top: 1.25%;
  width: 100%;
  background-color: white;
  padding-bottom: 5%;
  border-style: solid;
  border-width: 0;
  border-top-width: 1px;
  border-color: #00000030;
}

.leftSide h3 {
  font-size: 2.25vh;
}

#NewProfileInfo h3 {
  font-size: 1.75svh;
}

/* ProfileBio */
.ProfileBio {
  width: 90%;
}

.ProfileBio .EnterBio {
  background-color: white;
  padding-left: 2.5%;
  padding-top: 0.25vh;
  padding-bottom: 1vh;
  border-radius: 0.25vh;
  border-color: green;
}

.Experience .EnterBio {
  width: 100%;
}

.CertificateValue {
  margin-right: 10%;
  /* Adjust the percentage as needed */
}

.Experience .EditDescButton:hover {
  background-color: rgb(156, 83, 83);
}


.EnterBio {
  width: 100%;
}

.JobExp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.JobDem {
  display: flex;
  width: 66%;
  /* justify-content: space-between; */
  gap: 25%;
  padding-left: 6.33%;
  align-items: center;
}

.JobDem .input-title,
.JobDem .input-subtitle,
.input-right-side {
  width: 32%;
}

.title,
.subtitle,
.right-side {
  width: 33%;
  text-align: center;
}

#isEditing {
  width: 15%;
}

#isEditingSpecial {
  width: 5%;
  margin: 0;
  padding: 0;
  position: absolute;
  left: 27.5%;
}

#ProfileSectionNew input {
  border-radius: 10px;
  border-width: .5px;
  padding: 10px;
  padding-right: 15svh;
}

@media only screen and (max-width: 1024px) {
  #isEditingSpecial {
    width: 10%;
    margin: 0;
    padding: 0;
    position: absolute;
    left: 75%;
  }
  #DownloadResume{
    width: 25vh !important;
    border-radius: 10px !important;
  }

  #DownloadText{
    font-size: 1.25svh !important;
  }

  #FileUpload{
    width: 15vh !important;
    padding: 0 !important;
    font-size: 1.25svh !important;
  }
}

@media only screen and (max-width: 768px) {
  #isEditing {
    width: 25%;
    font-size: 1.5svh;
    padding: 0;
  }

  #isEditingSpecial {
    width: 15%;
    margin: 0;
    padding: 0;
    position: absolute;
    left: 75%;
    display: none;
  }

  #SwitcherProfile {
    width: 25vh !important;
    padding: 10% !important;
    text-wrap: nowrap;

  }

}

#TagRow{
  display: flex;
  gap: 2.5%;
}

#TagRow button {
  width: 15svh;
  color: black;
  background-color: #00000010;
  font-weight: 600;
  font-size: 1.5svh;
}
#activeSpecial{
  background-color: #34aed4 !important;
  color: white !important;
}

#TagRowLabel{
  font-size: 1.75svh;
  margin-bottom: 2.5%;
  font-weight: 500;
}

#TagRowSubLabel{
  font-size: 1.25svh;
}

#TagRowNewLabel{
  font-size: 1.75svh;
  margin-bottom: 0;
  font-weight: 500;
  margin-top: 2.5%;
}