.ForasPage {
    background-color: #f8f8f8;
    max-width: 1920px;
    display: flex;
    padding: 1%;
    min-height: 200vh;
    position: relative;
}

.ForasTag {
    background-color: black;
    line-height: 7.5vh;
    border-bottom-left-radius: 2.25vh;
    border-bottom-right-radius: 2.25vh;
}

.ForasTag .TagText {
    color: white;
    align-items: center;
    justify-content: center;
    align-self: center;
    align-content: center;
    margin-left: 2.5%;
}


.ForasTag .TagText h3 {
    font-size: 30px;
}

.ForasTag .TagText h3 span {
    color: #34AED4;
}

.ForasTag .TagText h4 {
    margin-top: -2.5%;
    font-size: 24px;
}

#FilterArea {
    padding: 1%;
    border-style: solid;
    border-width: 0;
    border-bottom-width: 1px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 5%;
    padding-left: 5%;
    margin-bottom: 5%;
}

#CategoryArea {
    padding-left: 5%;
}

#FilterArea a {}

#generateOptions {
   margin-left: 5%;
   margin-bottom: 5%;
}
#FilterTitle{
    margin-left: 5%;
    font-size: 2svh;
    font-weight: 600;
    margin-top: 5%;
}
.SearchForsa {
    /* width:70%; */
    position: -webkit-sticky; /* For Safari */
    position: sticky;
    top: 10px; 
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    padding-top: 1%;
    width: 25%;
    height: 100%;
    border-radius: 15px;
}

.SearchForsa input {
    line-height: 3.5vh;
    border-radius: 1vh;
    border: 2px solid #00000050;
    font-size: 16px;
}

.SearchForsa #search {
    width: 95%;
    margin-left: 2.5%;
    border-width: 1px;
}

.SearchForsa select {
    border-radius: 1vh;
    border: 2px solid #00000050;
    font-size: 14px;
    margin-left: 12.5%;
    padding: 2%;
}
#tag-label{
    width: 25svh;
    margin-bottom: 10%;
}

.SearchForsa button {
    margin-left: 1.25%;
    background-color: #34AED4;
    border-width: 0px;
    border-radius: 10px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    padding: .5%;
    padding-left: 2%;
    padding-right: 2%;
}

.SearchForsa button:hover {
    background-color: #34AED480;
    transition: 0.5s;
}

.displayTopicsSection {
    width: 50%;
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    gap: 20%;
    padding-left: 7.5%;

}

.displayTopicsSection button {
    margin-left: 1.25%;
    background-color: white;
    border-width: 0px;
    border-radius: 0;
    color: black;
    font-weight: 768;
    font-size: 1.5vh;
    margin-bottom: 5%;
    padding: 5%;
    text-align: left;
}

.displayTopicsSection button:hover {
    background-color: #34AED4;
    color: white;
    transition: .5s;
}

.displayTopicsSection button.active {
    background-color: #34AED4;
    color: white;
}

/* Job Display Section */

.JobDisplaySection {
    margin-top: 1.25%;
    padding-bottom: 2.5%;
    width: 75%;
    /* margin-left: 25%; */
}

.JobDisplaySection .leftSide {
    background-color: transparent;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 1vh;
    padding-right: 1.25vh;
    padding-bottom: 5vh;
}

.JobDisplaySection .rightSide .filterSide {
    background-color: #F6F6F6;
    padding: 10px;
    width: 80%;
}

.SearchForsa form label select {
    width: 100%;
    height: 4vh;
    padding-left: 3.5vh;
    padding-right: 3.5vh;
    margin-left: 2vh;
    font-size: 14px;
    border-radius: 10px;
}


.JobDisplaySection .rightSide {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2.5%;
    padding-left: 2.5%;
}

.JobDisplaySection .rightSide .jobBanner {}

.JobDisplaySection .rightSide .jobBanner .jobTitle {
    display: flex;
    flex-direction: row;
}

.JobDisplaySection .rightSide .jobBanner .jobTitle .leftJobTitle {
    width: 85%;
}

.JobDisplaySection .rightSide button {
    /* font-size: 2vh; background-color: #34AED4; border-width: 0;
    color:white; padding-top: .75vh; padding-bottom: .75vh; padding-left: 3.25vh;padding-right: 3.25vh;
    border-radius: 1.5vh; margin-top: .5vh; */
}

.filterCategory .CategoryRadio {
    margin-top: 2.5%;
}

.filterCategory .CategoryRadio input {
    margin-bottom: 2.5%;
}

#LoadMoreArea{
    display: flex;
    justify-content: center;
}

#LoadMoreButton{
    padding: 1%;
    border-radius: 20px;
    border-style: none;
    padding-left: 2.5%;
    padding-right: 2.5%;
    font-size: 1.5svh;
    font-weight: bold;
    background-color: #34AED4;
    color: white;
    cursor: pointer;
}

#LoadMoreButton:hover{
    color: #34AED4;
    background-color: white;
    transition: .4s;
}

@media only screen and (max-width: 1024px) {
    .SearchForsa {
        font-size: 12px;
    }

    .SearchForsa button {
        font-size: 12px;
    }

    .SearchForsa input {
        width: 30%;
        font-size: 10px;
    }

    .SearchForsa form label select {
        width: 90%;
        
    }
}

@media only screen and (max-width: 1024px) {
    .SearchForsa input[type=search] {
        display: none;
    }

    .SearchForsa button {
        width: 20%;
    }

    .JobDisplaySection .leftSide {}
}



@media only screen and (max-width: 768px) {
    .ForasTag .TagText h3 {
        font-size: 16px;
    }

    .ForasTag .TagText h4 {
        font-size: 14px;
    }

    .SearchForsa {
        /* margin-left: 5%; */
    }

    .JobDisplaySection .rightSide {
        /* margin-left: 5%; */
    }

    .displayTopicsSection button {
        height: 5vh;
        width: 20%;
        margin-left: 1.25%;
        font-size: 12px;
        margin-bottom: 2.5%;
    }

    .displayTopicsSection {
        width: 100%;

    }
}