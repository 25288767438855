#AboutWrapper {
    /* padding: 1%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

#VisionText {
    font-weight: 700;
    text-align: center;
}


#SubVisionText {
    font-weight: 700;
    text-align: center;
    font-size: 2svh;
}

#VisionSubText {
    font-weight: 300;
    padding-top: .5%;
    padding-left: 10%;
    padding-right: 10%;
    font-size: 2svh;
    letter-spacing: .125vh;
    line-height: 2.75svh;
}

#SubVisionSubText {
    font-weight: 500;
    padding-top: .5%;
    padding-left: 10%;
    padding-right: 10%;
    font-size: 1.75svh;
    letter-spacing: .125vh;
    line-height: 2.75svh;
}

#FirstBatchAbout {
    margin-top: 2.5%;
}

#RowImages {
    display: flex;
    flex-direction: row;
}

#RowImages #Col1Row1 {
    width: 50%;
    height: 50%;
}

#RowImages #Col2Row1 {
    width: 60%;
    height: 60%;
}

#RowImagesSecond {
    display: flex;
    flex-direction: row;
    margin-top: -28%;
}

#SectionAbout {
    margin-top: 2%;

}


#CompIntern {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 1.25%;
    border-style: solid;
    border-bottom-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    padding: 2.5%;
    padding-left: 0;
    padding-right: 0;
    border-color: #00000020;
}

#SwitcherImages {
    margin-top: 2%;
    width: 100%;
    height: 50svh;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 25%;
    padding-right: 25%;

}

#SwitcherImages img {}

#SwitcherImages h3,
h4 {
    color: white;
}


#SwitcherImages h3 {
    font-size: 5svh;
    font-weight: bold;
    font-weight: 600;
    margin: 0;
    padding: 0;
}


#SwitcherImages h4 {
    font-size: 3.25svh;
    font-weight: 200;
    margin: 0;
    padding: 0;
}

#TextDisplay {
    display: flex;
    flex-direction: column;
    align-items: center;
}



#CreateAccountAboutText {
    margin-top: 2.5%;
    width: 50%;
    background-color: #34AED4;
    color: white;
    cursor: pointer;
    font-size: 2svh;
    padding: 1%;
    border-radius: 10vh;
    font-weight: 600;
}

#CreateAccountAboutText:hover {
    color: #34AED4;
    background-color: white;
    scale: 1.1;
    transition: 1s ease-in;
    transition-timing-function: linear;
}

#OurMission{
    /* background-color: #34AED4; */
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1%;
    padding: 1.25%;
    margin-top: 1.25%;
    margin-bottom: 1.25%;
}
#MissionRegion{
    background-color: white;
    width: 33.33%;
    padding: 1.25%;
    padding-top: 0;
    border-radius: 20px;
    border-style: solid;
    border-color: #00000070;
    border-width: 0.5px;
    
}
#MissionRegion h4 {
    color: black;
    font-size: 1.75svh;
    font-weight: 300;
    line-height: 2.5svh;
}
#MissionTitle {
    border-bottom-style: solid;
    padding: 10%;
    border-bottom-color: #00000020;
    padding-bottom: 7.5%;
}

#MissionTitle h3{
    color: #34AED4;
    font-size: 2svh;
    text-wrap: nowrap;
    font-weight: 600;
}

@media only screen and (max-width: 1024px) {
    #RowImages #Col1Row1 {
        width: 60%;
        height: 60%;
    }

    #RowImages #Col2Row1 {
        width: 42.5%;
        height: 42.5%;
    }

    #RowImagesSecond #Col1Row2 {
        width: 70%;
        height: 70%;
    }

    #RowImagesSecond {
        margin-top: -7.5%;

    }

    #RowImagesSecond #Col2Row2 {
        width: 35%;
        height: 35%;

    }

    #VisionText {
        font-weight: 700;
        text-align: center;
        font-size: 1.5svh;
    }

    #VisionSubText {
        font-size: 1.25svh;
    }

    Sub#VisionText {
        font-weight: 700;
        text-align: center;
        font-size: 1.5svh;
    }

    #SubVisionSubText {
        font-size: 1.25svh;
    }

    #SwitcherImages {}

    #SwitcherImages h3 {
        font-size: 3svh;
        font-weight: bold;

    }


    #SwitcherImages h4 {
        font-size: 2svh;
        font-weight: 200;
        margin: 0;
        padding: 0;
    }

    #SwitcherCompIntern #Switcher {
        font-size: 1.25svh !important;
    }

    #CompIntern {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 1.25%;
        padding: 2.5%;
        padding-left: 0;
        padding-right: 0;
    }

}