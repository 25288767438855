.FooterSection {
    background-color: #34AED4;
    padding: 2.5%;
    display: flex;
    flex-direction: row;
    padding-bottom: 5%;
    justify-content: center;
    align-items: center;
    user-select: none;
}

#leftFooter {
    width: 65%;
    padding-left: 2.5%;
}

#rightFooter {
    width: 35%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#leftFooter .firstlinks {
    display: flex;
    gap: 5%;
    border-style: solid;
    border-width: 0;
    border-bottom-width: 1px;
    border-color: white;
    padding: 2.5%;
    padding-left: 0;
    margin-bottom: 2.5%;
}

.FooterSection a {
    text-decoration: none;
    color: white;
}

.FooterSection a:hover {
    text-decoration: underline;
    color: black;
    transition: 1s;
}


#rightFooter h1 {
    font-weight: 700;
    color: white;
    font-size: 5svh;
}

#secondlinks {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2.5%;

}

#secondlinks #followUs {
    font-weight: 700;
    font-size: 1.5svh;
    color: white;
}

#socialslinks {
    display: flex;
    align-items: center;
    gap: 30%;
}

#socialslinks a:hover{
    scale: 1.3;
    color: blue;
}

#AllRights {
    color: white;
    font-size: 1.5svh;
}

@media only screen and (max-width: 1024px) {
    #rightFooter h1 {
        font-weight: 700;
        color: white;
        font-size: 2.5svh;
    }
    .FooterSection a {
        text-decoration: none;
        color: white;
        font-size: 1.25svh;
    }
    #secondlinks h3{
       font-size: 1.25svh;
    }
}