.AddForasPage {
  background-color: #34AED410;
  padding: 2.5%;
  padding-bottom: 5%;
}

.AddForasPage .JobBackground {
  width: 100%;
  border-radius: 20px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.AddForasDetails img {
  width: 250px;
}

.FirstRowAdd {
  display: flex;
  flex-direction: row;
  margin-top: 1%;
  margin-bottom: 1%;
}

.FirstRowAdd input[type=date] {}

.AddForasDetails {
  display: flex;
  flex-direction: column;
  width: 62.5%;
  box-shadow: 0 .25svh .5svh rgba(0, 0, 0, 0.2);
  padding: 2.5%;
  border-radius: 20px;
  background-color: white;
}
#AddforasRow{
  display: flex;
  flex-direction: column;
  width: 48%;
}
#AddforasRow select {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  padding-left: 1%;
}

.AddForasDetails button {
  margin-bottom: 2.5%;
  background-color: #34AED4;
  color: white;
  border-style: none;
  font-size: 2svh;
  padding: 1.5% !important;
  border-radius: 10px;
  cursor: pointer;
}

.AddForasDetails button:hover {
  color: #34AED4;
  background-color: white;
  transition: .4s;
}

#AddNewForas {
  font-size: 2.5svh;
  font-weight: 600;
}

.FirstRowAdd {}
.JobCategory{
  border-width: .5px;
  width: 100%;
}
/* Card CSS */

/* Background Circle*/

.circles {
  position: absolute;
  height: 270px;
  width: 450px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.circle-1 {
  position: absolute;
  top: -50px;
  border-radius: 50%;
  background: radial-gradient(#006db3, #29b6f6);
  left: 10px;
  height: 160px;
  width: 160px;
}

.circle-2 {
  position: absolute;
  border-radius: 50%;
  background: radial-gradient(#006db3, #29b6f6);
  right: 40px;
  top: 80px;
  opacity: 0.8;
  height: 160px;
  width: 160px;
}

/* Card */

.card {
  width: 350px;
  height: 230px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  background: rgba(106, 116, 144, 0.7);
  border-radius: 10px;
  z-index: 1;
  overflow: hidden;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  position: relative;
  align-self: center;
  margin-left: 25px;
}

.NoCashWallet {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.visa_logo {
  float: right;
  padding: 10px;
}

.visa_logo img {
  width: 70px;
  height: 40px;
}

.visa_info {
  padding: 10px;
  margin: 30px 0;
}

.visa_info img {
  width: 60px;
  height: 45px;
}

.visa_info p {
  font-size: 22px;
  padding: 10px 0;
  letter-spacing: 2px;
  color: #ffffff;
}

.visa_crinfo {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  color: #ffffff;
}

/* Card CSS   */