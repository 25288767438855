#ProfileSectionNew {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding-bottom: 1%;
    padding-top: 2.5%;
    border-bottom: 1px solid #00000030;
    padding-left: 5%;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 100%;
  }
  
  .table {
    width: 80%;
    max-width: 80%;
    border-collapse: collapse;
    table-layout: fixed;
  }
  
  .tableHeader {
    font-weight: bold;
  }
  
  .tableRow {
  }
  
  .tableCell {
    padding: 8px;
    word-wrap: break-word;
    position: relative;
  }
  
  .h3Text {
    font-size: 1.5svh;
    font-weight: 600;
  }
  
  .linkButton {
    cursor: pointer;
    color: #34aed4;
    text-decoration: none;
    margin: 0 50px;
  }
  
  .tableText {
    font-size: 1.5svh;
    color: #00000060;
    font-weight: 600;
    text-align: left;
    padding-left: .75%;
  }
  
  .removeButton {
    color: red;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  
@media only screen and (max-width: 768px) {
    #ProfileSectionNew {
        padding-left: 1%;
      }

    .table {
        width: 97.5%;
        max-width: 100%;
      }
      .tableText {
        font-size: 1.5svh;
        text-wrap: nowrap;
    }
    .h3Text {
        font-size: 1.25svh;
      }
      .linkButton {
        cursor: pointer;
        color: #34aed4;
        text-decoration: none;
        margin: 0 50px;
      }
      #headerProfileSection{
        font-size: 2svh !important;
        padding: 2.5%;
        padding-left: 1%;
      }
}
