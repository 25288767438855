.ChatPage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ChatPage .ChatWrapper{
    width:90%;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 5px 10px, rgba(0, 0, 0, 0.23) 0px 3px 3px;
    border-radius: 40px;
    /* padding:10px; */
    height:92.5svh;
    /* margin-bottom: 10%; */
    margin-top: 5svh;
    border-style: solid;
    border-width: .5px;
    margin-bottom: 2.5%;
}

.ChatPage .ChatDivision{
    display: flex;
    height: 70svh;
}

.ChatPage .leftContacts{
    overflow: scroll;
    scroll-behavior: auto;
    border-right-style: solid;
    border-right-width: .5px;
    width: 25%;
    height: 92.5svh;
    padding-top: 2.5%;
}

.ChatPage .contact{
     cursor: pointer;
     color: black; /* Set text color for visibility */
     padding: 10px;
     margin: 5px;
     border: 0;
     display: flex;
     align-items: center;
     border-bottom-style: solid;
     border-width: .5px;
     padding-bottom: 2.5%;
     border-color: #00000030;
}

.ChatPage .contact:hover{
    background-color: lightgrey;
    
}

.ChatPage .contact:hover{
   background-color: blue;
   transition: .5s;
}

.RightMessages {
    width:75%;
    background-color: #34AED410;
    height: 75svh;
    /* padding-top: 2.5%; */
    /* padding: 2.5%; */
    border-top-right-radius: 40px;
    
}
#TopBar {
    padding: 1%;
    padding-top: 5%;
    padding-left: 2.5%;
    display: flex;
    align-items: center;
    gap: 1%;
    background-color: white;
    border-top-right-radius: 40px;
}
#TopBar img {
    border-radius: 50%;
    height: 5svh;
    width:5svh;
}

#TopBarCredentials{
    color: black;
    margin: 0;
    padding: 0;
    font-size: 1.5svh;
    font-weight: 700;
}
.RightMessages .MessageSpace{
    display: flex;  
    flex-direction: column-reverse;
    border-style: solid;
    border-width: 0;
    border-bottom-width: .25px;
    border-top-width: .25px;
    padding: 1%;
    width:100%;
    height: 80%;
    overflow: scroll;
    scroll-behavior:auto;
    overflow-y: scroll;
    scrollbar-width: 1px;
    scrollbar-color: black;
}


.RightMessages .MessageSpace .TextSent{
    align-self: flex-end;
    background-color: #34AED4;
    margin-bottom: 10px;
    width:30%;
    border-radius: 10px;
    font-size: 1.25svh;
    padding-left: 10px;
    padding-right: 10px;
    border-bottom-right-radius: 0px;
    line-height: 1.425svh;
    text-align: justify;
}

.RightMessages .MessageSpace .TextSent2{
    background-color: #00000010;
    width:30%;
    margin-bottom: 10px;
    font-size: 1.25svh;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    border-bottom-left-radius: 0px;
    line-height: 1.425svh;
    text-align: justify;
}

.RightMessages .MessageSpace .TextSentText{
    color: white;
}

.RightMessages .MessageSpace .TextSentText2{
    color: black;
}

.HandleSubmitMessage{
    background-color: white;
    padding: 1%;
    padding-top: 2.5%;
    padding-bottom: 2.5%;
    border-bottom-right-radius: 40px;
}
.HandleSubmitMessage form{
    display: flex;
    justify-content: center;
}

#message {
    width: 95%;
    padding: 10px;
    padding-left: 15px;
    border-radius: 40px;
    border-width: 0.25px;
    font-size: 1.25svh;
    resize: none; /* Prevents the user from resizing the textarea */
    overflow-wrap: break-word; /* Ensures text wraps within the textarea */
    overflow-y: auto; /* Adds a vertical scrollbar if content exceeds the height */
}


.HandleSubmitMessage form #submitMessage{
    width: 5svh;
    height: 5svh;
    border-radius: 50%;
    border-width: 0;
    align-self: center;
    background-color: #34AED4;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1%;
}

.HandleSubmitMessage form #submitMessage:hover{
    background-color: lightgrey;
    color: white;
    transition: .4s;
}

#EmptyMessageSpace{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 75svh;
}

#EmptyMessageLogo{
    font-size: 7.5svh;
    font-weight: 600;
    opacity: .1;
    margin: 0;
    padding: 0;
    margin-bottom: 1%;
}

#ClickChat{
    padding: 0;
    margin: 0;
    font-weight: 200;
    font-style: italic;
    font-size: 2svh;
}