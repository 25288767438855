.rightside .DisplayJobEmployer{
    background-color: white;
    width:22.5%;
    padding:2%;
    padding-top: 1%;
    margin-bottom: 1%;
    border-style: solid;
    border-width: .5px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.rightside .DisplayJobEmployer h4{
    font-weight: 400;
    font-size: 1.85svh;
    color: black;
}
#DisplayJobEmployerTitle{
    font-size: 1.85svh;
    padding: 2.5%;
}
#DisplayJobEmployerDesc{
    font-weight: 300;
    margin-top: 5%;
    margin-bottom: 5%;
    margin-left: 0;
    margin-right: 0;
    font-size: 1.5svh;
}
#genActiveEmployer{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 2.5%;
    margin-left: 5%;
}

#genActiveJobs{
    margin-left: 5%;
}
#WalletCredits{
    display: flex;
    align-items: center;
    margin-right: 2.5%;
    border-color: #00000050;
    border-style: solid;
    border-width: .5px;
    padding: 2.5%;
    border-radius: 20px;
}
#WalletCredits h3{
    font-size: 1.5svh;
    text-wrap: nowrap;
}

#EmployerProfileSwitcher{
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    margin-left: 2.5%;
    padding: 1%;
    padding-left: 2.5%;
    flex-wrap: wrap;
}
#ButtonEmployerProfile:hover{
    background-color: white;
    color: #34AED4;
    transition: .6s;
}
@media only screen and (max-width: 1024px) {
    #AddCreditsButton{
        display: none;
    }
    .rightside .DisplayJobEmployer{
        background-color: white;
        width:47.5%;
        padding:2%;
        padding-top: 1%;
        margin-bottom: 5%;
        border-style: solid;
        border-width: .5px;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    #EmployerProfileSwitcher #SwitcherButtons{
        font-size: 1.25svh !important;
        width: 22.5% !important;
    }
    #DisplayJobEmployerTitle{
        font-size: 1.5svh;
        padding: 2.5%;
    }
    .rightside .DisplayJobEmployer h4{
        font-weight: 400;
        font-size: 1.5svh;
        color: black;
    }
    #ButtonEmployerProfile{
        font-size: 1.25svh;
    }

    #genActiveEmployer{
        gap: 2.5%;
        margin-left: 0;
        justify-content: center;
    }
    #genActiveJobs{
        margin-left: 0;
        text-align: center;
        padding: 2.5%;
        font-size: 1.5svh;
    }
}


@media only screen and (max-width: 768px) {
    #AddCreditsButton{
        display: none;
    }
    .rightside .DisplayJobEmployer{
        background-color: white;
        width:75%;
        padding:2%;
        padding-top: 1%;
        margin-bottom: 5%;
        border-style: solid;
        border-width: .5px;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}