/* AlertModal.css */

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modal-overlay button{
    cursor: pointer;
  }
  
  .modal {
    background: white;
    padding: 20px;
    border-radius: 5px;
    max-width: 500px;
    width: 100%;
  }
  
  .modal-content {
    text-align: center;
  }
  
  .modal-content #ModalButton {
    margin: 5px;
    width: 15vh;
    border-radius: 10px;
    border-style: none;
    padding: 10px;
   
  }
  
  .modal-content #ModalButton:hover{
    transition: .4s;
    color: black !important;
  }