@import url('https://fonts.googleapis.com/css2?family=Days+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Convergence&family=Days+One&display=swap');
@font-face {
    font-family: 'LamaSans';
    src: url('fonts/LamaSans-Black.otf') format('opentype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'LamaSans';
    src: url('fonts/LamaSans-BlackItalic.otf') format('opentype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'LamaSans';
    src: url('fonts/LamaSans-Bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'LamaSans';
    src: url('fonts/LamaSans-BoldItalic.otf') format('opentype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'LamaSans';
    src: url('fonts/LamaSans-ExtraBold.otf') format('opentype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'LamaSans';
    src: url('fonts/LamaSans-ExtraBoldItalic.otf') format('opentype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'LamaSans';
    src: url('fonts/LamaSans-ExtraLight.otf') format('opentype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'LamaSans';
    src: url('fonts/LamaSans-ExtraLightItalic.otf') format('opentype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'LamaSans';
    src: url('fonts/LamaSans-Light.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'LamaSans';
    src: url('fonts/LamaSans-LightItalic.otf') format('opentype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'LamaSans';
    src: url('fonts/LamaSans-Medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'LamaSans';
    src: url('fonts/LamaSans-MediumItalic.otf') format('opentype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'LamaSans';
    src: url('fonts/LamaSans-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'LamaSans';
    src: url('fonts/LamaSans-RegularItalic.otf') format('opentype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'LamaSans';
    src: url('fonts/LamaSans-SemiBold.otf') format('opentype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'LamaSans';
    src: url('fonts/LamaSans-SemiBoldItalic.otf') format('opentype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'LamaSans';
    src: url('fonts/LamaSans-Thin.otf') format('opentype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'LamaSans';
    src: url('fonts/LamaSans-ThinItalic.otf') format('opentype');
    font-weight: 100;
    font-style: italic;
}

*{
    font-family: 'LamaSans', sans-serif;
    font-weight: 400;
    scroll-behavior: smooth;
    /* background-color: blue; */
    /* background-color: #EDF3FB; */
    max-width: 1920px;
 
}

*::-webkit-scrollbar{
    display: none;
}

button:hover{
    color: #34AED4 !important;
    background-color: white !important;
    transition: .4s !important;
    user-select: none;
}